@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.text-primary {
  color: rgb(241, 130, 71) !important;
}

body a.text-primary:hover,
body a.text-primary:focus {
  color: rgb(241, 130, 71) !important;
}

.text-primary {
  color: rgb(241, 130, 71) !important;
}
.btn-primary {
  background-color: rgb(241, 130, 71) !important;
  border-color: rgb(241, 130, 71) !important;
}
.btn-primary:hover {
  background-color: #ca1300 !important;
  border-color: #ca1300 !important;
}
.btn-outline-primary {
  color: rgb(241, 130, 71) !important;
  border-color: rgb(241, 130, 71);
}

.btn-outline-primary:hover {
  background: rgb(241, 130, 71);
  border-color: rgb(241, 130, 71);
  color: #fff !important;
}

.text-justify {
  text-align: justify !important;
}

.page-title {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  min-height: 168px;
}
.page-title-img {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.page-title h1 {
  font-size: 2.2rem;
}
.page-title h5 {
  font-size: 1.2rem;
}
.navbar ul li {
  position: relative;
}

.navbar ul li a {
  position: relative;
  text-decoration: none;
  padding: 12px 8px;
  display: block;
  color: #333;
}
.navbar > ul > li > a {
  font-weight: 600;
}

.navbar ul li a:hover {
  color: rgb(241, 130, 71);
}

.navbar ul li ul {
  display: none;
  background: rgb(18, 70, 128);
  z-index: 1;
  position: absolute;
  left: 24px;
  top: 48px;
  min-width: 248px;
  border-radius: 4px;
  padding: 8px 0px 4px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.navbar ul li ul::before {
  position: absolute;
  top: -6px;
  left: 20px;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(18, 70, 128);
  font-size: 0px;
  line-height: 0px;
}

.navbar ul li:hover ul {
  display: inherit;
}

.navbar ul li ul li a {
  color: #fff;
  padding: 8px 12px;
  display: block;
  border-bottom: solid 1px rgba(256, 256, 256, 0.3);
}

.navbar ul li ul li a:hover {
  background: #fc7f0c;
  color: #fff;
}

.navbar ul li ul li:last-child a {
  border-bottom: 0px none;
}
.home-body h2 {
  font-size: 36px;
  position: relative;
  font-weight: 600;
}
.home-body h2::after {
  content: "";
  background: #333;
  height: 2px;
  width: 12%;
  position: absolute;
  left: 0px;
  bottom: -16px;
  left: 0px;
  right: 0px;
  margin: auto auto;
}
.home-slider {
  position: relative;
  min-height: 492px;
  background: #0044ae;
}
.home-slide {
  animation: fadeIn 0.3s ease-in;
}
.home-slider-content {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  margin: auto;
  width: 64%;
  padding: 12px 24px;
  border-radius: 8px;
  color: #fff;
  transform: translateY(-50%);
}
.home-slider-content h2 {
  color: #ffd54f;
  font-weight: 600;
  font-size: 42px;
  padding: 0px;
  margin: 0px 0px 24px 0px;
}
.home-slider-content ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.home-slider-content ul li {
  position: relative;
  padding: 0px 0px 0px 28px;
  margin: 0px 0px 8px 0px;
  list-style: none;
  font-weight: 600;
}
.home-slider-content ul li i {
  position: absolute;
  left: 0px;
  top: 0px;
}
.home-slider-content ul li i::before {
  content: "check_circle";
  color: #fff;
  font-size: 22px;
}
.home-slider-content .btn {
  margin-top: 12px;
}
.home-slider .next-btn,
.home-slider .prev-btn {
  position: absolute;
  right: 4%;
  top: 50%;
  margin: auto;
  transform: translateY(-50%);
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  border: 0px none;
  height: 42px;
  width: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-slider .prev-btn {
  right: auto;
  left: 4%;
}
.home-slider .next-btn i::before {
  content: "arrow_forward_ios";
  color: #fff;
  font-size: 22px;
}
.home-slider .prev-btn i::before {
  position: relative;
  content: "arrow_back_ios";
  color: #fff;
  font-size: 22px;
  left: 4px;
}
.home-technology-solutions .col-lg-3 {
  border-right: solid 1px rgba(0, 0, 0, 0.05);
}
.home-technology-solutions .col-lg-3:last-child {
  border: 0px none;
}

.home-travelcarma-for {
  background: url(../images/home-customer.webp) center center no-repeat;
  background-size: cover;
  padding: 24px 0px 42px 0px;
}
.home-travelcarma-for li {
  background: #166191;
  padding: 6px 16px;
  margin: 0px 0px 8px 0px;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
}
.home-suppliers {
  background: url(../images/home-supplier-bg.webp) center center no-repeat;
  background-size: cover;
  padding: 42px 0px;
  position: relative;
}
.home-suppliers::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}
.home-suppliers h2::after {
  background: #fff;
}
.home-what-different {
  background: url(../images/home-whyus-bg.webp) center center no-repeat;
  background-size: cover;
  padding: 42px 0px;
  position: relative;
}
.home-what-different::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}
.home-what-different h2::after {
  background: #fff;
}
.home-what-different-box {
  background: rgba(256, 256, 256, 0.8);
  border-radius: 8px;
  padding: 24px 48px;
}
.home-what-different-box h4 {
  font-weight: 600;
  margin-top: 16px;
}
.home-blog-box {
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  border-radius: 8px;
}
.home-blog-box img {
  border-radius: 8px 8px 0px 0px;
}
.home-blog-box h5 {
  font-size: 1.17rem;
}

.suppliers-logos {
  list-style: none;
  padding: 0px;
  margin: 0px -8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.suppliers-logos li {
  flex: 0 0 auto;
  width: 14.285%;
  padding: 8px;
  margin: 0px;
  list-style: none;
}

.suppliers-logos img {
  background: #fff;
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  border-radius: 8px;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.leadership-team-box {
  border: solid 1px rgba(0, 0, 0, 0.05);
}

.shadow-box li div {
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  height: 100%;
  border-radius: 8px;
  padding: 24px 16px;
}

.shadow-box li div h3 {
  font-size: 22px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0px 0px 16px 0px;
  margin: 8px 0px 16px 0px;
}

.page-inside h2 {
  position: relative;
  font-weight: 600;
  font-size: 2.8rem;
}
.page-why-us h2 {
  position: relative;
}
.page-inside h2::after,
.page-why-us h2::after {
  content: "";
  background: #333;
  height: 2px;
  width: 12%;
  position: absolute;
  left: 0px;
  bottom: -16px;
  left: 0px;
  right: 0px;
  margin: auto auto;
}
.event-box li div {
  background: #fff;
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  border-radius: 8px;
  min-height: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.page-booking-engine h2 {
  font-size: 3rem;
  margin-bottom: 82px;
  font-weight: 600;
}
.benefits-section h2 {
  font-size: 2.2rem;
  margin-bottom: 42px;
  color: rgb(241, 130, 71);
}
.benefits-section h2::after {
  width: 90%;
  height: 1px;
  bottom: -22px;
}
.benefits-section ul {
  text-align: left;
}
.benefits-section ul li {
  margin-top: 16px;
}
.benefits-section button {
  margin-top: 8px;
}
.product-slider {
  width: 100%;
  height: 320px;
  border: solid 1px rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.02);
  padding: 8px;
}
.product-slider-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: zoom-in;
}
.product-slider-wrap img {
  width: 100%;
  /* height: 100%; */
  /* object-fit: contain; */
}
.product-slider-btn {
  padding: 0px;
  margin: 16px 16px 0px 16px !important;
  border: 0px none;
  background: none;
}
.product-slider-mobile {
  width: 50%;
  height: auto;
  margin: 0px auto;
}

.section-separator {
  width: 100%;
  background: url(../images/info-sap.webp) center center no-repeat;
  height: 160px;
}
.benefits-section-bg {
  background: linear-gradient(
    to bottom,
    #fff 0%,
    rgb(187, 222, 251, 0.5) 49%,
    #fff 99%
  );
}
.benefits-section-bg ul li {
  margin-bottom: 16px;
  padding-left: 36px;
  position: relative;
}
.benefits-section-bg ul li i {
  position: absolute;
  left: 0px;
  top: 0px;
}
.benefits-section-bg ul li i::before {
  content: "check_circle";
  color: #1976d2;
}
.benefits-section-bg h3 {
  position: relative;
}
.benefits-section-bg h3::after {
  content: "";
  background: #333;
  height: 2px;
  width: 6%;
  position: absolute;
  left: 0px;
  bottom: -16px;
  left: 0px;
  right: 0px;
  margin: auto auto;
}
.product-highlights li {
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.product-highlights figure {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 2px #eaeaea;
  margin: 0px 16px 0px 0px;
  padding: 0px;
}

.solutions-section h2 {
  font-size: 2.2rem;
  margin-bottom: 42px;
  color: rgb(241, 130, 71);
}
.solutions-section h2::after {
  width: 90%;
  height: 1px;
  bottom: -22px;
}
.solutions-section p {
  font-size: 1.1rem;
  line-height: 36px;
}
.solutions-section ul {
  text-align: left;
  padding: 0px;
  margin: 42px 0px 0px 0px;
}
.solutions-section ul li {
  margin-top: 16px;
  list-style: none;
  position: relative;
  padding-left: 20px;
}
.solutions-section ul li::before {
  content: "";
  background: url(../images/bullet-1.webp) center center no-repeat;
  height: 18px;
  width: 18px;
  position: absolute;
  left: 0px;
  top: 5px;
}
.solutions-section button {
  margin-top: 8px;
}

.solutions-separator {
  height: 82px;
}
.solution-highlights figure {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 2px #eaeaea;
  margin: 0px 16px 0px 0px;
  padding: 0px;
}

.available-model-box {
  text-align: center;
}
.available-model-box ul li {
  margin-bottom: 16px;
  padding-left: 36px;
  position: relative;
  text-align: left;
}
.available-model-box ul li i {
  position: absolute;
  left: 0px;
  top: 0px;
}
.available-model-box ul li i::before {
  content: "check_circle";
  color: #1976d2;
}
.am-box-blue,
.am-box-green {
  min-height: 442px;
}
.am-box-blue > div {
  background: #009dd9;
}
.am-box-green > div {
  background: #9bbb59;
}
.am-box-blue i::before {
  color: #009dd9 !important;
}
.am-box-green i::before {
  color: #9bbb59 !important;
}

.solutions-faqs > ul > li {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 8px 24px;
  margin: 10px;
  border-radius: 8px;
}

.tdx-boxes li {
  margin-bottom: 16px;
}
.tdx-boxes li h5 {
  background: #380085;
  color: #fff;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  padding: 12px 16px;
  margin: 0px;
  display: flex;
  align-items: center;
}
.tdx-boxes li h5 i::before {
  content: "check_circle";
  color: #fff;
  margin-right: 8px;
}
.page-faqs h5 i {
  margin-right: 8px;
  top: 5px;
  position: relative;
}
.page-faqs h5 i::before {
  content: "remove_circle_outline";
}
.page-faqs h5 {
  padding: 0px;
  margin: 0px 0px 8px 0px;
}
.page-faqs p {
  padding: 0px 0px 0px 36px;
  margin: 0px 0px 24px 0px;
}
.ebookbox img {
  max-width: 66%;
}

.infographics-box {
  display: flex;
  flex-wrap: wrap;
  padding: -8px;
}
.infographics-box li {
  /* box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  height: 100%;
  border-radius: 8px;
  padding: 24px 16px; */

  flex: 0 0 auto;
  max-width: 20%;
  padding: 8px 8px;
}

.infographics-box li div {
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  height: 100%;
  border-radius: 8px;
  padding: 24px 16px;
}

.infographics-box img {
  max-width: 100%;
}
.infographics-box h5 {
  font-size: 1rem;
  padding: 0px;
  margin: 16px 0px;
}

.infographics-box figure {
  min-height: 152px;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.customers-logos li img {
  border-radius: 0px;
  padding: 4px;
}

.testimonial-box {
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  height: 100%;
  border-radius: 8px;
  padding: 24px 24px;
  text-align: center;
}

.casestudies-box div {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  height: 100%;
  border-radius: 8px;
  padding: 24px 24px;
  text-align: center;
}
.footer-links {
  margin-top: 62px;
}
.footer-links,
footer {
  background: #404040;
  border-top: solid 6px #2e2e2e;
  color: #fff;
  padding: 24px 0px;
}
.footer-links ul li ul {
}
.footer-links a {
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
}
.footer-links ul li a {
  font-size: 0.9rem;
  position: relative;
  padding-left: 16px;
}
.footer-links ul li a:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 4px;
  top: 8px;
}
.country-list {
  background: #2e2e2e;
  color: #fff;
  padding: 16px 0px 8px 0px;
  font-size: 0.7rem;
}
.SocialIcons a {
  padding: 0px 5px !important;
}
.SocialIcons a::before {
  display: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.list-sm-arr {
  list-style: none;
}
.list-sm-arr li {
  position: relative;
  padding-left: 20px;
}
.list-sm-arr li::before {
  content: "";
  position: absolute;
  background: url(../images//list-sm-arrow.webp) left top no-repeat;
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
}

.list-box {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.list-box li {
  padding: 12px 16px;
  margin: 0px 0px 16px 0px;
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  color: rgb(241, 130, 71) !important;
}

.nav-tabs .nav-link {
  background: #f1f1f1;
  color: #666;
  border-bottom: solid 1px #dee2e6;
  font-weight: bold;
  margin-right: 2px;
}
.nav-tabs .nav-link.active {
  border-color: rgb(241, 130, 71);
  background: rgb(241, 130, 71);
  color: #fff;
}

.list-tab-arr {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.list-tab-arr li {
  position: relative;
  padding: 0px 0px 0px 28px;
  margin: 0px 0px 12px 0px;
}
.list-tab-arr li::before {
  content: "";
  position: absolute;
  background: url(../images/list-tab-arr.webp) left top no-repeat;
  left: 0px;
  top: 4px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  background-size: 80%;
}
.list-tab-arr-white li::before {
  background: url(../images/list-tab-arr-white.webp) left top no-repeat;
}

@media (max-width: 575px) {
  header .navbar {
    display: none;
  }
  .home-slider {
    min-height: 340px;
    height: 340px;
  }
  .home-slide {
    height: 100%;
  }
  .home-slide img {
    object-fit: cover;
    height: 100%;
  }
  .home-slide::before {
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }
  .home-slider-content {
    background: none;
    padding: 0px;
    text-align: center;
    width: calc(100% - 80px);
  }
  .home-slider-content h2 {
    padding: 0px;
    font-size: 1.6rem;
    margin: 0px;
    text-align: center;
  }
  .home-slider-content ul,
  .home-slider-content p {
    display: none;
  }
  .home-body h2 {
    font-size: 1.8rem;
  }
  .home-slider-mobile {
    font-size: 1rem;
    display: block;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
    display: block !important;
  }
  .home-slider .next-btn,
  .home-slider .prev-btn {
    height: 32px;
    width: 32px;
  }
  .home-technology-solutions .col-lg-3 {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
  .home-technology-solutions .col-lg-3:last-child {
    border: 0px none;
  }
  .home-travelcarma-for {
    background: none;
    text-align: center;
  }
  .home-travelcarma-for h4 {
    margin: 0px 0px 16px 0px !important;
    font-size: 1.2rem;
  }
  .home-travelcarma-for li {
    font-size: 1rem;
    justify-content: center;
  }
  .home-platform {
    margin-top: 0px !important;
  }
  .page-title h1 {
    font-size: 1.8rem;
  }
  .page-title h5 {
    font-size: 0.9rem;
  }
  .suppliers-logos li {
    width: 50%;
  }
  .page-booking-engine h2,
  .page-inside h2 {
    font-size: 1.6rem;
  }
}
.error-red {
  color: red;
  font-size: 0.9rem;
}

.home-feature-box li div {
  box-shadow: 0px 0px 12.04px 1.96px rgba(199, 199, 199, 0.75) inset;
  height: 100%;
  border-radius: 8px;
  padding: 24px 16px;
  cursor: pointer;
}
.home-feature-box li div:hover {
  background: rgb(241, 130, 71) !important;
}
.home-feature-box li div:hover h5 {
  color: #fff;
}
.home-feature-box li div:hover img {
  -webkit-filter: brightness(0) invert();
  filter: brightness(0) invert();
}

.home-feature-box li div.active {
  background: rgb(241, 130, 71) !important;
}
.home-feature-box li div.active h5 {
  color: #fff;
}
.home-feature-box li div.active img {
  -webkit-filter: brightness(0) invert();
  filter: brightness(0) invert();
}
.home-feature-section li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 16px;
}

.home-feature-section li::before {
  content: "";
  position: absolute;
  background: url(../images//list-sm-arrow.webp) left top no-repeat;
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
}

.page-twlite h2 {
  font-size: 2.4rem;
}
.home-feature-section h2 {
  position: relative;
  font-weight: 600;
  font-size: 2.8rem;
}
.home-feature-section h2::after {
  content: "";
  background: #999;
  height: 2px;
  width: 24%;
  position: absolute;
  left: 0px;
  bottom: -16px;
  right: auto;
  margin: auto auto;
}
.tclite-howitworks h3 {
  margin-top: 24px;
  font-size: 1.4rem;
  color: rgb(241, 130, 71);
  cursor: pointer;
}
.tclite-howitworks h3 img {
  margin-right: 16px;
}
.tclite-howitworks h3:hover img {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.tclite-howitworks ul {
  list-style: none;
  padding-left: 42px;
}
.tclite-howitworks ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 16px;
}
.tclite-howitworks ul li::before {
  content: "";
  position: absolute;
  background: url(../images//list-sm-arrow.webp) left top no-repeat;
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
}
